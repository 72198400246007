<template>
  <MDBContainer fluid class="increaseYourImpact" id="increaseYourImpact">
    <MDBRow class="mx-sm-3 mx-md-5 pt-3 pt-5 pe-3">
      <MDBCol sm="3" md="5"></MDBCol>

      <MDBCol col="12" sm="9" md="7"
        class="ps-4 pe-xl-4 pb-4 ps-md-0 d-flex flex-column text-center text-sm-start justify-content-center align-items-center align-items-md-start"
      >
        <header class="sub py-3 py-md-4">
          Increase your impact with Better Wealth
        </header>
        
        <article class="sub pt-3 pt-md-4 pe-lg-0 pb-5">
          <p>
            By tackling one of the most significant challenges to wellbeing - financial stress - 
            you can move your employee benefits program to a higher plane,
            as well as offering a real point of difference for new hires.
          </p>
          <p>
            Helping your colleagues build the habits to achieve financial security could 
            also be your lasting legacy - one that delivers for a lifetime benefit to 
            your team members and their families.
          </p>
        </article>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow } from "mdb-vue-ui-kit";

export default {
  name: "IncreaseYourImpact",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow
  }
};
</script>

<style scoped></style>