<template>
  <MDBContainer fluid class="howBetterWealthCanHelp" id="howBetterWealthCanHelp">
    <MDBRow class="mx-sm-3 mx-md-5 pt-4">
    <MDBCol col sm="1"></MDBCol>
    <MDBCol sm="10">
      <div class="d-xl-flex mb-3">
        <div class="p-2 variable-width-col text-center text-lg-start px-3 ps-md-0 pe-md-4 pt-5 pb-0 pb-xl-5 d-flex flex-column justify-content-center align-items-center align-items-xl-start">
          <header class="sub py-3 py-md-4">
            How Better Wealth can help your team
          </header>
          
          <article class="sub pt-3 pt-md-4 pe-lg-0 text-center text-md-start">
            <p>
              Unlike a traditional financial literacy education program, Better Wealth offers 
              your team members a personalised plan to set, and achieve, their financial goals.
            </p>
            <p>
              The platform delivers easy-to-follow actions that can help reduce financial stress 
              and allow your employees to focus on achieving their potential, instead of their money worries.
            </p>
          </article>
          
          <nav class="py-4 py-md-5">
            <MDBBtn color="primary" size="lg" rounded class="px-5" @click="showModal">Find out more</MDBBtn>
          </nav>
        </div>

        <div class="fixed-width-col d-flex text-center flex-column justify-content-center align-items-center">
          <img src="../assets/img/betterwealth-tracker2.png" />
        </div>

        <div class="p-2 variable-width-col text-center text-lg-start pt-lg-3 px-3 px-md-4 ps-xl-0 pb-5 mb-4 mb-xl-0 d-flex flex-column justify-content-center align-items-center">
          <div class="blueBox px-5 pb-4 pt-xl-5 mt-xl-4 text-start">
            <ul>
              <li>
                Am I tracking ok to build my family's future?
              </li>
            </ul>
            <div class="dots"></div>
            <ul>
              <li>
                When will we be able to get on the property ladder?
              </li>
            </ul>
            <div class="dots"></div>
            <ul>
              <li>
                What would happen if I lost my job?
              </li>
            </ul>
            <div class="dots"></div>
            <ul>
              <li>
                What will our life look like post-retirement?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </MDBCol>
    <MDBCol col sm="1"></MDBCol>
    </MDBRow>
    
    
    <!-- <MDBRow class="mx-sm-3 mx-md-5 pt-4">
      <MDBCol col sm="1"></MDBCol>
      
      <MDBCol lg="4" class="text-center text-lg-start px-3 ps-md-0 pe-md-4 py-5 d-flex text-center flex-column justify-content-center align-items-center">
        <header class="sub py-3 py-md-4">
          How Better Wealth can help your team
        </header>
        
        <article class="sub pt-3 pt-md-4 pe-lg-0 text-center text-md-start">
          <p>
            Unlike a traditional financial literacy education program, Better Wealth offers 
            your team members a personalised plan to set, and achieve, their financial goals.
          </p>
          <p>
            The platform delivers easy-to-follow actions that can help reduce financial stress 
            and allow your employees to focus on achieving their potential, instead of their money worries.
          </p>
        </article>
        
        <nav class="py-4 py-md-5">
          <MDBBtn color="primary" size="lg" rounded class="px-5">Book a video demo</MDBBtn>
        </nav>
      </MDBCol>

      <MDBCol col sm="2" class="fixed-width px-0 py-5 d-flex text-center flex-column justify-content-center align-items-center">
        <img src="../assets/img/betterwealth-tracker2.png" />
      </MDBCol>

      <MDBCol lg="4" class="ps-0 text-center text-lg-start pe-3 pe-md-0 py-5 d-flex flex-column justify-content-center align-items-center">
        <div class="blueBox">
          <ul>
            <li>
              Am I tracking ok to build my family's future?
            </li>
            <li class="dots"></li>
            <li>
              When will we be able to get on the property ladder?
            </li>
            <li class="dots"></li>
            <li>
              What would happen if I lost my job?
            </li>
            <li class="dots"></li>
            <li>
              What will our life look like post-retirement?
            </li>
          </ul>
        </div>
      </MDBCol>

      <MDBCol col sm="1"></MDBCol>
    </MDBRow> -->
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: "HowBetterWealthCanHelp",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn
  },

  methods: {
    showModal () {
      this.$emit("showModal")
    }
  }
};
</script>

<style scoped></style>