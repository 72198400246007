import { createApp } from 'vue'
import App from './App.vue'

import { ObserveVisibility } from 'vue3-observe-visibility';
import VueGtag from "vue-gtag";

createApp(App)
  .directive('observe-visibility', {
    beforeMount: ObserveVisibility.beforeMount, 
    update: ObserveVisibility.update, 
    unmounted: ObserveVisibility.unbind
  })
  .use(VueGtag, {
    config: {
      id: "G-N52BN8R35K",
      params: {
        cookie_domain: 'mybetterwealth.com.au',
        cookie_flags: 'SameSite=None;Secure',
        appName: 'My Better Wealth',
        pageTrackerScreenviewEnabled: true,
        send_page_view: true
      }
    }
  })
  .mount('#app')
