<template>
  <MDBContainer fluid class="footer pt-3" id="footer">
    <MDBRow class="mx-sm-3 mx-md-5 py-5">
      <MDBCol col sm="1"></MDBCol>

      <MDBCol sm="10" class="px-5 px-sm-0">
        <div class="d-flex flex-column flex-md-row text-center">
          <div class="p-2 flex-fill text-start align-self-center pb-4 pb-md-0">
            <img
              src="../assets/img/gallagher-logo.png"
              style="width: 220px;"
            />
          </div>
          <div class="p-2 flex-fill text-end text-white align-self-center pt-4 contact">
            Contact Us 
            <a href="tel:1300850757"><strong>1300 850 757</strong></a>
          </div>
        </div>
      </MDBCol>

      <MDBCol col sm="1"></MDBCol>
    </MDBRow>

    <MDBRow class="mx-sm-3 mx-md-5">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        <hr style="border: 0; height: 1px; color: #fff; opacity: 60%;" />
      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>
    
    <MDBRow class="mx-sm-3 mx-md-5 py-5">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        <div class="d-flex flex-column flex-lg-row text-center nav">
          <div class="p-2 flex-fill">
            &copy; Gallagher Benefit Services Pty Ltd 2021
          </div>
          <div class="p-2 flex-fill">
            <a target="_blank" href="https://gallagherbenefits.com.au/privacy-policy/">Privacy Policy</a>
          </div>
          <div class="p-2 flex-fill">
            ABN 49 611 343 803
          </div>
          <div class="p-2 flex-fill">
            <a target="_blank" href="https://gallagherbenefits.com.au/financial-services-guide-and-representative-profiles/">FSG &amp; Representative Profiles</a>
          </div>
          <div class="p-2 flex-fill">
            AFSL 488001
          </div>
        </div>
      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow } from "mdb-vue-ui-kit";

export default {
  name: "Footer",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow
  }
};
</script>

<style scoped></style>