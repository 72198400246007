<template>
  <MDBContainer fluid class="seeForYourself pt-3" id="seeForYourself">
    <MDBRow class="mx-sm-3 mx-md-5 pt-5 pb-md-3">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol col="12" md="7" lg="6" class="ps-5 ps-sm-3 pe-3 pe-sm-4 ps-md-0">
        <header class="sub py-3 py-md-4">
          See for yourself how Better Wealth can help
        </header>
        
        <article class="sub pt-3 pt-md-4 pb-2 pe-md-5 pe-lg-0">
          <p>In your short video demo, you will see:</p>

          <ul>
            <li>How Better Wealth's personalised plans can benefit any team member</li>
            <li>How easy Better Wealth is to roll out for an organisation of any size</li>
            <li>The measurement tools that you use to show how Better Wealth delivers benefits to both your team members and your organisation</li>
          </ul>
        </article>
        
        <nav class="d-none d-md-flex pt-3 pb-3 mb-3">
          <MDBBtn color="secondary" size="lg" rounded class="px-5" @click="showModal">Find out more</MDBBtn>
        </nav>
      </MDBCol>
      <MDBCol md="4" lg="5" class="d-none d-md-flex"><!-- col="2" sm="3" -->
        <a href="javascript:;" @click="showVideo">
          <img src="../assets/img/pixel.png" class="overlay" title="Click to launch video">
        </a>
      </MDBCol>
    </MDBRow>

    <MDBRow class="seeForYourselfSmall pt-5 pb-3 d-flex d-md-none">
      <MDBCol col="12" class="justify-content-center align-items-center text-center">
        <a href="javascript:;" @click="showVideo">
          <img src="../assets/img/pixel.png" class="overlay2" title="Click to launch video">
        </a>
        
        <br />
        
        <nav class="pt-5 pb-3 mb-3">
          <MDBBtn color="secondary" size="lg" rounded class="px-5" @click="showModal">Find out more</MDBBtn>
        </nav>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: "SeeForYourself",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn
  },

  methods: {
    showVideo () {
      this.$emit("showVideo")
    },

    showModal () {
      this.$emit("showModal")
    }
  }
};
</script>

<style scoped></style>