<template>
  <MDBContainer fluid class="header" id="header">
    <MDBRow class="mx-sm-3 mx-md-5 py-4">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10" class="text-center text-md-start">
        <img
          src="../assets/img/gallagher-logo.png"
          style="width: 220px;"
        />
      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow } from "mdb-vue-ui-kit";

export default {
  name: "Header",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow
  }
};
</script>

<style scoped></style>