<template>
  <div class="mbw">
    <Header />
    <TeamBenefits />
    <WhatIsBetterWealth @showModal="showModal" />
    <LeadingIcons />
    <WhyBetterWealth />
    <HowBetterWealthCanHelp @showModal="showModal" />
    <HowBetterWealthDelivers @showModal="showModal" />
    <IncreaseYourImpact />
    <ImpactIcons @showModal="showModal" />
    <SeeForYourself @showVideo="showVideo" @showModal="showModal" />
    <Footer />
    <ContactForm ref="contactForm" />
    <VideoModal ref="videoModal" />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import TeamBenefits from './components/TeamBenefits.vue'
import LeadingIcons from './components/LeadingIcons.vue'
import WhatIsBetterWealth from './components/WhatIsBetterWealth.vue'
import WhyBetterWealth from './components/WhyBetterWealth.vue'
import HowBetterWealthCanHelp from './components/HowBetterWealthCanHelp.vue'
import HowBetterWealthDelivers from './components/HowBetterWealthDelivers.vue'
import IncreaseYourImpact from './components/IncreaseYourImpact.vue'
import ImpactIcons from './components/ImpactIcons.vue'
import SeeForYourself from './components/SeeForYourself.vue'
import Footer from './components/Footer'
import ContactForm from './components/ContactForm'
import VideoModal from './components/VideoModal'

import { scroller } from 'vue-scrollto/src/scrollTo'

//@scrollTo="scrollTo"

export default {
  name: 'App',
  components: {
    Header,
    TeamBenefits,
    LeadingIcons,
    WhatIsBetterWealth,
    WhyBetterWealth,
    HowBetterWealthCanHelp,
    HowBetterWealthDelivers,
    IncreaseYourImpact,
    ImpactIcons,
    SeeForYourself,
    Footer,
    ContactForm,
    VideoModal
  },

  methods: {
    showModal () {
      this.$refs.contactForm.toggleModal()
    },

    showVideo () {
      this.$refs.videoModal.toggleModal()
    },

    scrollTo (target) {
      const scrollMeTo = scroller()
      scrollMeTo('#' + target)
    }
  }
}
</script>

<style lang="scss">
$primary: #1E6098;
$secondary: #FFB300;
$dark: #011D2F;

@import '~@/../mdb/scss/index.free.scss';

@import 'assets/fonts/gallagherslab.css';
@import 'assets/fonts/gotham.css';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#app {
  font-family: 'Gotham-Book', Roboto, Helvetica, Arial, sans-serif;
}

.btn {
  font-weight: 400;
  font-family: 'Gotham-Medium';
}

.header {
  background-color: #001C2E;
}

.v-divider {
  width: 1px;
  height: 40%;
}

.v-full-divider {
  width: 1px;
  height: 80%;
}

.h-divider {
  border: 0;
  height: 1px;
  width: 25%;
  color: #707070;
  opacity: 0.5;
}

.loop-item {
  padding-right: 5px;
}

.loop-item.sign {

}

.graph-box {
  min-height: 300px;
}

header {
  color: #03263C;
  font-size: 2.3rem;
  line-height: 2.6rem;
  font-weight: 400;
  font-family: 'GallagherSlabMedium';

  &.sub {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

article {
  color: #5C5C5C;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  font-family: 'Gotham-Book';

  &.sub {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.teamBenefits {
  min-height: 400px;
  background-color: #fdab00;
  background-position: left top;
  background-size: auto 180%;
  background-repeat: no-repeat;
  @include media-breakpoint-up(sm) {
    background-image: url('./assets/img/hero-banner.jpg');
  }

  .teamBenefitsSmall {
    min-height: 440px;
    background-image: url('./assets/img/hero-banner.jpg');
    background-position: calc(69% - 100px) top;
    background-size: auto 150%;
    @media (max-width: 399.98px) {
      background-position: calc(67% - 100px) 44%;
      background-size: auto 110%;
    }
    background-repeat: no-repeat;
  }

  header {
    color: #fff;
  }

  article {
    color: #fff;
  }
}

.leadingIcons {
  .icon {
    width: 80px;
    height: 80px;
  }

  label {
    font-weight: 400;
    font-family: 'GallagherSlabLight';
  }

  article {
    &.iconBox {
      font-size: 0.96rem;
      line-height: 1.2rem;
    }
  }
}

.impactIcons {
  .icon {
    width: 80px;
    height: 80px;
  }

  label {
    font-weight: 400;
    font-family: 'GallagherSlabLight';
  }

  article {
    &.iconBox {
      font-size: 0.96rem;
      line-height: 1.2rem;
    }
  }
}

.whyBetterWealth {
  background-color: #FEEDCF; //#FDB712;
  
  label {
    font-weight: 400;
    font-family: 'GallagherSlabLight';
  }

  article {
    color: #4f4f4f;
    &.graphTag {
      font-size: 0.96rem;
      line-height: 1.2rem;
    }
  }
}

.howBetterWealthDelivers {
  background-color: #FEEDCF;

  label {
    font-weight: 400;
    font-family: 'GallagherSlabLight';
  }

  .form-text {
    margin-bottom: 5px;
  }

  .form-control,
  .input-group-text {
    font-weight: bold;
  }

  .disclaimer {
    font-size: 0.78rem;
    line-height: 0.99rem;
  }
}

.increaseYourImpact {
  > div.row {
    min-height: 450px;
  }
  background-image: url('./assets/img/betterwealth-impact.jpg');
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
}

.howBetterWealthCanHelp {
  .blueBox {
    padding: 15px;
    background-color: #011D2F;
    color: #fff;
    border-radius: 0 10px 10px 0;
    
    @media (max-width: 1199.98px) {
      border-radius: 10px;
      padding-top: 110px;
    }
    // @media (min-width: 1633px) {
    //   border-radius: 10px;
    // }

    ul {
      list-style: none;
      padding-left: 2em;
      padding-bottom: 5px;
    }

    li {
      position: relative;
      margin-bottom: 3px;

      &:before {
        content: '';
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url('./assets/icons/icon-tick.png');
        background-image: url('./assets/icons/icon-tick.svg');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 0.3em;
        vertical-align: text-top;
        left: -2em;
        position: absolute;
      }
    }

    .dots {
      background-image: url('./assets/img/dashes.gif');
      background-repeat: repeat-x;
      height: 1px;
      margin-bottom: 20px;
      opacity: 0.7;

      @media (max-width: 1199.98px) {
        margin-top: 23px;
        margin-bottom: 30px;
      }
    }
  }

  .fixed-width-col {
    img {
      z-index: 2;
      @media (max-width: 1199.98px) {
        width: 180px;
      }
    }

    @media (max-width: 1199.98px) {
      margin-bottom: -100px;
    }
  }

  @media (min-width: 1200px) {
    .fixed-width-col {
      width: 219px;
    }

    .variable-width-col {
      width: 49%;
    }
  }
}

.seeForYourself {
  min-height: 550px;
  background-color: #017bac;
  background-position: 45% 33%;
  background-size: cover;
  background-repeat: no-repeat;
  @include media-breakpoint-up(md) {
    background-image: url('./assets/img/betterwealth-video.jpg');
  }

  .seeForYourselfSmall {
    min-height: 440px;
    background-image: url('./assets/img/betterwealth-video.jpg');
    background-position: calc(67% - 100px) 22%;
    background-size: auto 150%;
    @media (max-width: 399.98px) {
      background-position: calc(67% - 100px) 44%;
      background-size: auto 110%;
    }
    background-repeat: no-repeat;
  }

  header {
    color: #fff;
  }

  article {
    color: #fff;
  }

  ul {
    list-style:none;
    padding-left: 2em;
  }

  li {
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      background-image: url('./assets/icons/icon-tick.png');
      background-image: url('./assets/icons/icon-tick.svg');
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 0.1em;
      vertical-align: text-top;
      left: -2em;
      position: absolute;
    }
  }

  .overlay {
    width: 150%;
    height: 100%;
    margin-left: -80px;
  }

  .overlay2 {
    width: 280px;
    height: 350px;
  }
}

.footer {
  background-color: #011D2F;
  
  .nav,
  .nav a {
    font-size: 0.8rem;
    color: #ddd;
  }

  .nav a:hover {
    color: #fff;
  }
  
  .contact {
    font-size: 1.15rem;
    font-family: 'GallagherSlabLight';
    
    strong {
      padding-left: 10px;
      font-weight: 400;
      font-family: 'GallagherSlabMedium';
    }

    a {
      color: #ddd;
      &:hover {
        color: #fff;
      }
    }
  }
}




@include media-breakpoint-up(sm) {
  .teamBenefits {
    background-position: 45% bottom;
    background-size: cover;
  }

  .increaseYourImpact {
    background-position: 43% top;
  }
}

@include media-breakpoint-up(md) {
  .teamBenefits {
    background-position: 60% 55%;
  }

  .increaseYourImpact {
    background-position: 55% top;
  }

  header {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
  
  article {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  .teamBenefits {
    min-height: 630px;
    background-position: 80% bottom;
  }

  .seeForYourself .overlay {
    width: 50%;
    height: 100%;
    margin-left: 0;
  }

  header {
    font-size: 3rem;
    line-height: 3.7rem;

    &.sub {
      font-size: 2.2rem;
      line-height: 2.5rem;
    }
  }
  
  article {
    font-size: 1.2rem;
    line-height: 1.6rem;

    &.sub {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .teamBenefits {
    background-position: right top;
  }

  header {
    font-size: 3rem;
    line-height: 3.7rem;
  }
  
  article {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 580px) {
  .seeForYourself .overlay {
      width: 200%;
      margin-left: calc(-100% + 40px);
  }
}

@include media-breakpoint-up(xxl) {
  .teamBenefits {
    min-height: 750px;
  }
}

.mbw {
  max-width: 1560px;
  background-color: #fff;
}

@media (min-width: 1560px) {
  body {
    background-color: #eee;
  }

  .mbw {
    margin-left: auto;
    margin-right: auto;
  }
}


// .container-fluid { /* TEMPORARY */
//   border: 1px solid #ff0000;
// }
</style>
