<template>
  <MDBContainer fluid class="teamBenefits" id="teamBenefits">
    <MDBRow class="mx-sm-3 mx-md-5 pt-3 pt-md-5">
      <MDBCol col="12" sm="9" md="8" lg="6" xxl="5" class="pt-3 pt-sm-0 ps-4 pe-4 pe-sm-5 pe-md-4 ps-md-0 ps-xl-5 text-center text-sm-start">
        <header class="py-3 py-md-4">
          Give your team the benefit of thriving financially
        </header>
        
        <article class="pt-3 pt-md-4 pb-sm-5 pe-sm-5 pe-lg-5">
          Better wealth is a smart suite of financial planning tools that have been shown 
          to reduce financial stress, improve wellbeing and boost productivity.
        </article>
        
        <!-- <nav class="d-none d-md-flex pt-4 pb-5 pt-md-5">
          <MDBBtn
            color="primary"
            size="lg"
            rounded
            class="px-5"
            @click="$emit('scrollTo', 'whatIsBetterWealth')"
          >
            Learn more
          </MDBBtn>
        </nav> -->
      </MDBCol>
      <MDBCol sm="3" md="4" lg="6"></MDBCol>
    </MDBRow>

    <MDBRow class="teamBenefitsSmall pt-5 pb-3 d-flex d-sm-none">
      <MDBCol col="12" class="justify-content-center align-items-center text-center">
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer, 
  MDBCol, 
  MDBRow, 
  //MDBBtn
} from "mdb-vue-ui-kit";

export default {
  name: "TeamBenefits",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    //MDBBtn,
  }
};

</script>

<style scoped></style>