<template>
  <MDBContainer fluid class="whyBetterWealth" id="whyBetterWealth">
    <MDBRow class="mx-sm-3 mx-md-5 pt-3">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        
        <header class="sub pt-5 pb-4 text-center">
          Why Better Wealth?
        </header>
        
        <article class="sub pt-3 pt-md-4 text-center">
          <p>
            Gallagher Benefits exists to build a happier, healthier Australian workforce. 
            Our research shows us that financial worries are a major cause of stress for employees 
            across all industries and types of work.
          </p>
        </article>

      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>

    <MDBRow class="mx-3 px-md-5 mx-md-5 pt-3 pb-5">
      <MDBCol
        lg="4"
        class="graph-box text-center px-1 px-md-5 px-lg-1 py-4"
        v-observe-visibility="{ callback: isFinanceMattersVisible, once: true }"
      >
        <!-- <label>
          <p><strong>Financial Stress</strong></p>
        </label> -->
        <apexchart
          v-if="graphStatus.financeMattersVisible"
          width="85%"
          type="radialBar"
          :options="graphData('#000').chartOptions"
          :series="graphValue('financeMattersVisible', 45).series"
          class="d-flex justify-content-center align-items-center pb-3"
        ></apexchart>

        <article class="graphTag px-3">
          <p>of employees say finance matters cause them the most stress</p>
        </article>
      </MDBCol>

      <MDBCol
        lg="4"
        class="graph-box text-center show-graph px-1 px-md-5 px-lg-1 py-4"
        v-observe-visibility="{ callback: isFinancialLiteracyVisible, once: true }"
      >
        <!-- <label>
          <p><strong>Financial Help</strong></p>
        </label> -->
        <apexchart
          v-if="graphStatus.financialLiteracyVisible"
          width="85%"
          type="radialBar"
          :options="graphData('#239886').chartOptions"
          :series="graphValue('financialLiteracyVisible', 63).series"
          class="d-flex justify-content-center align-items-center pb-3"
        ></apexchart>

        <article class="graphTag px-3">
          <p>expect more help with their financial literacy from their Employer</p>
        </article>
      </MDBCol>

      <MDBCol
        lg="4"
        class="graph-box text-center px-1 px-md-5 px-lg-1 py-4"
        v-observe-visibility="{ callback: isChangingJobsVisible, once: true }"
      >
        <!-- <label>
          <p><strong>Job Changes</strong></p>
        </label> -->
        <apexchart
          v-if="graphStatus.changingJobsVisible"
          width="85%"
          type="radialBar"
          :options="graphData('#f51369').chartOptions"
          :series="graphValue('changingJobsVisible', 23).series"
          class="d-flex justify-content-center align-items-center pb-3"
        ></apexchart>
        
        <article class="graphTag px-3">
          <p>of staff change jobs in the first 12 months of employment, costing Employers $3.8bn per year*</p>
        </article>
      </MDBCol>
    </MDBRow>

  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow } from "mdb-vue-ui-kit";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "WhyBetterWealth",

  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    apexchart: VueApexCharts,
  },

  data: function() {
    return {
      graphStatus: {
        financeMattersVisible: false,
        financialLiteracyVisible: false,
        changingJobsVisible: false
      }
    }
  },

  methods: {
    isFinanceMattersVisible(isVisible) {
      setTimeout(() => this.graphStatus.financeMattersVisible = isVisible, 400);
      //this.graphStatus.financeMattersVisible = isVisible;
    },

    isFinancialLiteracyVisible(isVisible) {
      setTimeout(() => this.graphStatus.financialLiteracyVisible = isVisible, 400);
      //this.graphStatus.financialLiteracyVisible = isVisible;
    },

    isChangingJobsVisible(isVisible) {
      setTimeout(() => this.graphStatus.changingJobsVisible = isVisible, 400);
      //this.graphStatus.changingJobsVisible = isVisible;
    },


    graphValue (whichGraph, graphValue) {
      if (this.graphStatus[whichGraph]) {
        return {series: [graphValue]}
      } else {
        return {series: []}
      }
    },

    graphData (graphColor) {
      return {
      chartOptions: {
        noData: {  
          text: "Loading...",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontFamily: "GallagherSlabMedium",
            fontSize: '18px',
            color: graphColor
          }  
        },
        chart: {
          height: "auto",
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1600,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 850
            }
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 0,
              size: '76%',
              background: 'transparent'
            },
            track: {
              background: '#000',
              strokeWidth: '100%',
              margin: 0,
              opacity: 0.06
            },
            dataLabels: {
              value: {
                offsetY: -10,
                fontFamily: 'GallagherSlabMedium',
                fontSize: '24px',
                color: graphColor
              }
            }
          },
        },
        fill: {
          colors: [graphColor]
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [''],
      },
    };
    }
  }
};
</script>

<style scoped></style>