<template>
  <MDBContainer fluid class="impactIcons" id="impactIcons">
    <!-- <MDBRow class="mx-sm-3 mx-md-5 pb-3 pt-3">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        
        <header class="sub pt-5 pb-3 text-center">
          Lorem ipsum dolor
        </header>
        
        <article class="sub pt-2 text-center">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit...
          </p>
        </article>

      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow> -->

    <MDBRow class="mx-5 px-5 pt-5">
      <!-- <MDBCol col sm="1"></MDBCol> -->

      <MDBCol lg="3" class="text-center ps-3 pe-3 ps-lg-4 pe-lg-0 py-4">
        <p><img src="../assets/icons/icon-workplace.svg" class="icon" /></p>
        <label>
          <p><strong>Positive Workplace</strong></p>
        </label>
        <article class="iconBox">
          <p>Introduce a workplace benefit that delivers a positive impact on your team member's lives.</p>
        </article>
      </MDBCol>

      <MDBCol col sm="1" class="d-none d-lg-flex text-center flex-column justify-content-center align-items-end ps-lg-0">
        <img src="../assets/icons/divider.gif" class="v-divider" />
      </MDBCol>
      <MDBCol col lg="1" class="py-4 d-flex d-lg-none text-center flex-column justify-content-center align-items-center">
        <hr class="h-divider" />
      </MDBCol>

      <MDBCol lg="4" class="text-center ps-3 pe-3 px-lg-5 py-4">
        <p><img src="../assets/icons/icon-happy.svg" class="icon" /></p>
        <label>
          <p><strong>Happier Team</strong></p>
        </label>
        <article class="iconBox">
          <p>Create a happier, more productive team with higher levels of engagement and lower staff turnover.</p>
        </article>
      </MDBCol>

      <MDBCol col sm="1" class="d-none d-lg-flex text-center flex-column justify-content-center align-items-start pe-lg-0">
        <img src="../assets/icons/divider.gif" class="v-divider" />
      </MDBCol>
      <MDBCol col lg="1" class="py-4 d-flex d-lg-none text-center flex-column justify-content-center align-items-center">
        <hr class="h-divider" />
      </MDBCol>

      <MDBCol lg="3" class="text-center ps-3 pe-3 pe-lg-4 ps-lg-0 py-4">
        <p><img src="../assets/icons/icon-attendance.svg" class="icon" /></p>
        <label>
          <p><strong>Valued Benefit</strong></p>
        </label>
        <article class="iconBox">
          <p>Deliver a measurable impact on your organisation's bottom line by reducing absentee rates and recruitment costs.</p>
        </article>
      </MDBCol>

      <!-- <MDBCol col sm="1"></MDBCol> -->
    </MDBRow>

    <MDBRow class="mx-sm-3 mx-md-5 pb-5">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        <nav class="py-4 py-md-5 text-center">
          <MDBBtn color="primary" size="lg" rounded class="px-5" @click="showModal">Find out More</MDBBtn>
        </nav>
      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: "ImpactIcons",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn
  },

  methods: {
    showModal () {
      this.$emit("showModal")
    }
  }
};
</script>

<style scoped></style>