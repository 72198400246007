<template>
  <div class="flip-number">
    <template v-for="(item, index) in splitNumbers">
      <template v-if="typeof(item) === 'number'">
        <flip-number-item
        :value="item"
        :speed="speed"
        class="loop-item"
        :key="index"
      ></flip-number-item>
      </template>
      
      <template v-else>
        <!-- <div class="loop-item sign" :key="index">{{item}}</div> -->
        <flip-number-item
        :value="item"
        :speed="speed"
        class="loop-item sign"
        :key="index"
      ></flip-number-item>

      </template>
    </template>
  </div>
</template>
<script>

import FlipNumberItem from './FlipNumberItem.vue'

export default {
  name: 'FlipNumber',

  components: {
    FlipNumberItem
  },

  props: {
    value: {
      type: String,
      required: true,
      default: '0'
    },

    speed: {
      type: [Number, String],
      default: 600
    }
  },

  data () {
    let _speed = this.speed * 1
    if (isNaN(_speed)) {
      console.error('the prop speed is not a number')
      _speed = 600
    } else {
      _speed = _speed < 600 ? 600 : _speed
    }

    return {
      splitNumbers: [],
      interval: _speed
    }
  },

  created () {
    this.splitValue()
  },

  watch: {
    value () {
      this.splitValue()
    }
  },

  methods: {
    splitValue () {
      this.splitNumbers = []
      const valueSplitArr = this.value.toString().split('')

      for (let i = 0; i < valueSplitArr.length; i++) {
        let _val = valueSplitArr[i]
        
        if (isNaN(Number(_val))) {
          this.splitNumbers.push(_val)
        } else {
          this.splitNumbers.push(Number(_val))
        }
      }
    }
  }
}
</script>

<style scoped>

.loop-item {
  display: inline-block;
}

</style>
