<template>
  <MDBContainer fluid class="howBetterWealthDelivers" id="howBetterWealthDelivers">
    <MDBRow class="mx-sm-3 mx-md-5 pt-4">
      <MDBCol col sm="1"></MDBCol>
        
      <MDBCol lg="4" class="text-center text-lg-start px-3 ps-md-0 pe-md-1 py-4">
        <header class="sub py-3 py-md-4">
          How Better Wealth can deliver for your business
        </header>
        
        <article class="sub pt-3 pt-md-4 pe-lg-0 text-center text-md-start">
          <p>
            When your employees are financially stressed, their productivity suffers.
          </p>
          <p>
            Research shows that 7 in 10 employees spend time at work on personal financial concerns 
            every week, which can have a significant impact on your business' bottom line.
          </p>
          <p>
            Use our calculator to see how much an employee's financial stress could be costing your business.
          </p>
        </article>
        
        <nav class="py-4 py-md-5">
          <MDBBtn color="primary" size="lg" rounded class="px-5" @click="showModal">Find out more</MDBBtn>
        </nav>
      </MDBCol>

      <MDBCol col sm="2" class="d-none d-lg-flex text-center flex-column justify-content-center align-items-center">
        <img src="../assets/icons/divider.gif" class="v-full-divider" />
      </MDBCol>
      <MDBCol col lg="2" class="py-4 d-flex d-lg-none text-center flex-column justify-content-center align-items-center">
        <hr class="h-divider" />
      </MDBCol>

      <MDBCol lg="4" class="text-center text-lg-start px-3 pe-md-0 ps-md-1 py-5">
        <label>
          <p><strong>
            Enter your values in the fields below to immediately see how much financial stress costs your business
          </strong></p>
        </label>

        <br />
        <form class="needs-validation" novalidate @submit.prevent="calculate">

          <div class="form-text">Number of employees</div>
          <MDBInput
            inputGroup
            :formOutline="false"
            v-model="noOfEmployees"
            class="border-0 bg-white form-control has-validation"
            type="number"
            required
          >
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </MDBInput>
          
          <br />
          
          <div class="form-text">Average hourly rate</div>
          <MDBInput
            inputGroup
            :formOutline="false"
            v-model="avgHourlyRate"
            class="border-0 bg-white form-control has-validation"
            type="number"
            required
          >
            <template #prepend>
              <span class="border-0 bg-white input-group-text">$</span>
            </template>
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </MDBInput>

          <br />

          <MDBBtn color="secondary" size="lg" class="px-5 me-2 mt-3" type="submit">Calculate</MDBBtn>
          <MDBBtn outline="dark" class="border-0 ms-2 my-1" @click="reset">Reset</MDBBtn>
        
        </form>

        <br /><br /><br />
        
        <label>
          <p><strong>
            Estimated cost to workforce
          </strong></p>
        </label>

        <br />

        <flip-number
          :value="estimate"
          :speed="200"
        ></flip-number>

        <p class="pt-1">Monthly*</p>

        <p class="disclaimer">
          * Calculated based on your provided data. Australian Bureau of Statistics and Gallagher's 2019 
           Financial Confidence Survey metrics which interviewed over 1000 Australian employees. Metrics 
           include: average hours per week that an employee spends concerned about or dealing with 
           personal finances at work and a 46 week work-year.
        </p>
      </MDBCol>

      <MDBCol col sm="1"></MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBInput } from "mdb-vue-ui-kit";
import FlipNumber from './FlipNumber.vue'

export default {
  name: "HowBetterWealthDelivers",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBInput,

    FlipNumber
  },

  mounted () {
    const form = document.querySelector('.needs-validation');
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }
      form.classList.add('was-validated')
    }, false)
  },

  methods: {
    showModal () {
      this.$emit("showModal")
    },

    calculate () {
      if (
        isNaN(this.noOfEmployees) || 
        this.noOfEmployees === null || 
        this.noOfEmployees === '' || 
        isNaN(this.avgHourlyRate) || 
        this.avgHourlyRate === null || 
        this.avgHourlyRate === ''
      ) {
        this.estimate = '$0000';
        return false;
      } else {
        const weeklyCostCalculation = this.noOfEmployees * this.avgHourlyRate * 1.82;
        const annualCostCalculation = weeklyCostCalculation * 46;
        const monthlyCostCalculation = annualCostCalculation / 12;
        this.estimate = '$' + monthlyCostCalculation.toFixed(0)
      }
    },

    reset () {
      this.noOfEmployees = null;
      this.avgHourlyRate = null;
      this.estimate = '$0000';
      const form = document.querySelector('.needs-validation');
      form.classList.remove('was-validated')
    }
  },

  data () {
    return {
      estimate: '$0000',
      noOfEmployees: null,
      avgHourlyRate: null
    }
  },
};
</script>

<style scoped></style>