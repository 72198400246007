<template>
    <MDBModal
      id="contactModal"
      tabindex="-1"
      labelledby="contactModalTitle"
      v-model="contactModal"
      centered
      staticBackdrop
      size="lg"
    >
      <div class="p-5 text-center" v-show="loading">
        <MDBSpinner />
      </div>
      <form class="contact-us needs-validation" novalidate @submit.prevent="sendMessage" ref="contactUs" v-show="!loading">
        <MDBModalHeader :close="false" class="bg-primary">
          <MDBModalTitle id="contactModalTitle" class="text-white">Request a callback</MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody>
          <label class="pt-3">Your Name</label>
          <MDBInput
            inputGroup
            v-model="name"
            class="has-validation py-2"
            type="text"
            name="name"
            required
          >
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </MDBInput>

          <label class="mt-2 pt-3">Email Address</label>
          <MDBInput
            inputGroup
            v-model="email"
            class="has-validation py-2"
            type="email"
            name="email"
            required
          >
            <div class="invalid-feedback">
              Please enter a valid email address.
            </div>
          </MDBInput>

          <label class="mt-2 pt-3">Contact Number</label>
          <MDBInput
            inputGroup
            v-model="phone"
            class="has-validation py-2"
            type="tel"
            name="phone"
            required
          >
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </MDBInput>

          <label class="mt-2 pt-3">Your Organisation</label>
          <MDBInput
            inputGroup
            v-model="organisation"
            class="has-validation py-2"
            type="text"
            name="organisation"
            required
          >
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </MDBInput>

          <label class="mt-2 pt-3">Organisation Size</label>
          <div class="form-outline input-group">
            <select
              class="form-select has-validation"
              v-model="size"
              name="size"
              required
            >
              <option disabled selected value="">Please select an option</option>
              <option value="0-30">0 - 30</option>
              <option value="30-100">30 - 100</option>
              <option value="100-200">100 - 200</option>
              <option value="200-500">200 - 500</option>
              <option value="500+">500+</option>
            </select>
            <div class="invalid-feedback">
              Please enter a value.
            </div>
          </div>

          <label class="mt-2 pt-3">Message (Optional)</label>
          <MDBTextarea
            v-model="message"
            name="message"
            rows="5"
            class="form-control has-validation py-2"
          >
          </MDBTextarea>

        </MDBModalBody>
        <MDBModalFooter class="justify-content-center align-items-center pb-3">
          <MDBBtn outline="dark" class="mx-2" @click="contactModal = false">Close</MDBBtn>
          <MDBBtn color="primary" class="mx-2" type="submit">Send my Enquiry</MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>

    <MDBModal
      id="postSendModal"
      tabindex="-1"
      labelledby="postSendModalTitle"
      v-model="postSendModal"
      centered
      staticBackdrop
    >
    <MDBModalBody class="p-3 text-center">
      <div class="text-center">
        {{ responseText }}
      </div>
      <MDBBtn color="primary" class="mt-3 mx-2" @click="responseBtn">Close</MDBBtn>
    </MDBModalBody>

    </MDBModal>
</template>

<script>
import { ref } from 'vue';
import { MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBtn, MDBInput, MDBTextarea, MDBSpinner } from 'mdb-vue-ui-kit';
import emailjs from 'emailjs-com';

export default {
  name: "ContactForm",
  components: {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBTextarea,
    MDBSpinner,
  },

  mounted () {
    this.contactModal = ref(false);
  },

  data () {
    return {
      responseText: '',
      name: '',
      email: '',
      phone: '',
      organisation: '',
      size: '',
      message: '',
      contactModal: false,
      postSendModal: false,
      sendError: false,
      loading: false,
    }
  },

  methods: {
    responseBtn () {
      this.postSendModal = false
      if (!this.sendError) {
        this.reset()
      }
    },

    toggleModal() {
      this.contactModal = !this.contactModal
    },

    sendMessage(e) {
      const form = this.$refs.contactUs;
      if (!form.checkValidity()) {
        e.preventDefault()
        e.stopPropagation()
      }
      form.classList.add('was-validated')

      if (
        this.name === null || 
        this.name === '' || 
        this.email === null || 
        this.email === '' || 
        this.phone === null || 
        this.phone === '' || 
        this.organisation === null || 
        this.organisation === '' || 
        this.size === null || 
        this.size === ''
      ) {
        return false;
      } else {
        this.loading = true

        console.log({
          form,
          name: this.name,
          email: this.email,
          phone: this.phone,
          organisation: this.organisation,
          size: this.size,
          message: this.message
        })

        // emailjs.send("service_l5azyta", "template_nr4w7id",{
        //   name: "dgsdfgsfdg",
        //   email: "sadfadsf@sdfasdfasdf.com",
        //   phone: "234324234324",
        //   organisation: "sdfsadfasdfdsaf",
        //   size: "15-200",
        //   message: "test testt test",
        // });

        emailjs.sendForm(
          'service_l5azyta',
          'template_nr4w7id',
          form,
          'user_tjcHn7zcbXADPNFEIUs1h', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            organisation: this.organisation,
            size: this.size,
            message: this.message
          }
        )
        .then((result) => {
            console.log('SUCCESS!', result.text);
            this.postSendModal = true
            this.responseText = 'Thank you, your message was sent successfully!'
            this.loading = false
            this.contactModal = false
        }, (error) => {
            console.log('FAILED...', error.text);
            this.postSendModal = true
            this.sendError = true
            this.responseText = 'Sorry, there was an error sending your message - please check your details and try again.'
            this.loading = false
        });

      }
    },

    reset () {
      this.sendError = false
      this.responseText = ''
      this.name = ''
      this.email = ''
      this.phone = ''
      this.organisation = ''
      this.message = ''
      //const form = this.$refs.contactUs; //document.querySelector('.contact-us');
      //form.classList.remove('was-validated')
    }
  }
};
</script>

<style scoped>
* {box-sizing: border-box;}

input[type=text],
[type=email],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
</style>
