<template>
    <MDBModal
      id="videoModal"
      tabindex="-1"
      labelledby="videoModalTitle"
      v-model="videoModal"
      centered
      staticBackdrop
      size="xl"
    >
      <MDBModalHeader :close="false" class="bg-primary">
        <MDBModalTitle id="videoModalTitle" class="text-white">How Better Wealth can help</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="videoContainer">
        <iframe 
          src="https://player.vimeo.com/video/530542184?h=58034e7ca5&autoplay=1&title=0&byline=0&portrait=0" 
          style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          frameborder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowfullscreen
        ></iframe>
      </MDBModalBody>
      <MDBModalFooter class="justify-content-center align-items-center pb-3">
        <MDBBtn outline="dark" class="mx-2" @click="toggleModal">Close</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
</template>

<script>
import { ref } from 'vue';
import { MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdb-vue-ui-kit';

export default {
  name: "VideoModal",
  components: {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
  },

  mounted () {
    this.videoModal = ref(false);
    let vimeoScript = document.createElement('script')
      vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
      document.head.appendChild(vimeoScript)
  },

  data () {
    return {
      name: '',
      email: '',
      message: '',
      videoModal: false,
    }
  },

  methods: {
    toggleModal() {
      this.videoModal = !this.videoModal
    },
  }
};
</script>

<style scoped>
* {box-sizing: border-box;}

.videoContainer {
  padding: 56.25% 0 0 0;
  position: relative;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
