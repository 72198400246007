<template>
  <MDBContainer fluid class="leadingIcons" id="leadingIcons">
    <MDBRow class="mx-sm-3 mx-md-5 pb-3">
      <MDBCol col sm="1"></MDBCol>
      <MDBCol sm="10">
        
        <header class="sub pt-5 pb-3 text-center">
          More knowledge, more confidence
        </header>
        
        <!-- <article class="sub pt-2 text-center">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit...
          </p>
        </article> -->

      </MDBCol>
      <MDBCol col sm="1"></MDBCol>
    </MDBRow>

    <MDBRow class="mx-5 px-5 pt-3">
      <!-- <MDBCol col sm="1"></MDBCol> -->

      <MDBCol lg="3" class="text-center ps-3 pe-3 ps-lg-4 pe-lg-0 py-4">
        <p><img src="../assets/icons/icon-team.svg" class="icon" /></p>
        <label>
          <p><strong>Educate</strong></p>
        </label>
        <article class="iconBox">
          <p>Help your team better understand their financial health</p>
        </article>
      </MDBCol>

      <MDBCol col sm="1" class="d-none d-lg-flex text-center flex-column justify-content-center align-items-end ps-lg-0">
        <img src="../assets/icons/divider.gif" class="v-divider" />
      </MDBCol>
      <MDBCol col lg="1" class="py-4 d-flex d-lg-none text-center flex-column justify-content-center align-items-center">
        <hr class="h-divider" />
      </MDBCol>

      <MDBCol lg="4" class="text-center ps-3 pe-3 px-lg-5 py-4">
        <p><img src="../assets/icons/icon-goal.svg" class="icon" /></p>
        <label>
          <p><strong>Empower</strong></p>
        </label>
        <article class="iconBox">
          <p>Empower employees to set goals, model scenarios and track performance</p>
        </article>
      </MDBCol>

      <MDBCol col sm="1" class="d-none d-lg-flex text-center flex-column justify-content-center align-items-start pe-lg-0">
        <img src="../assets/icons/divider.gif" class="v-divider" />
      </MDBCol>
      <MDBCol col lg="1" class="py-4 d-flex d-lg-none text-center flex-column justify-content-center align-items-center">
        <hr class="h-divider" />
      </MDBCol>

      <MDBCol lg="3" class="text-center ps-3 pe-3 pe-lg-4 ps-lg-0 py-4">
        <p><img src="../assets/icons/icon-stress.svg" class="icon" /></p>
        <label>
          <p><strong>Reduce Stress</strong></p>
        </label>
        <article class="iconBox">
          <p>Reduce financial stress and improve workplace performance</p>
        </article>
      </MDBCol>

      <!-- <MDBCol col sm="1"></MDBCol> -->
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow } from "mdb-vue-ui-kit";

export default {
  name: "LeadingIcons",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow
  }
};
</script>

<style scoped></style>