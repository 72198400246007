<template>
  <MDBContainer fluid class="whatIsBetterWealth" id="whatIsBetterWealth">
    <MDBRow class="mx-sm-3 mx-md-5 pt-3">
      <MDBCol col="12" md="7" class="py-5 px-5 ps-md-0 align-items-center">
        <img
          src="../assets/img/betterwealth-platform.jpg"
          style="max-width: 99%;"
        />
      </MDBCol>

      <MDBCol col="12" md="5"
        class="pe-xl-4 pb-4 ps-md-0 d-flex text-center text-md-start flex-column justify-content-center align-items-center align-items-md-start"
      >
        <header class="sub py-3 py-md-4">
          What is Better Wealth?
        </header>
        
        <article class="sub pt-3 pt-md-4 pe-lg-0 text-center text-md-start">
          <p>
            Better wealth is an online financial wellness platform that empowers your team members 
            to create a personalised plan to manage their money better.
          </p>
          <p>
            Better Wealth's fully mobile platform allows your team to engage on their own terms 
            and at their own pace, aligning with their individual circumstances and working habits.
          </p>
        </article>
        
        <nav class="py-4 py-md-5">
          <MDBBtn color="primary" size="lg" rounded class="px-5" @click="showModal">Find out more</MDBBtn>
        </nav>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";
//$emit('scrollTo', 'whyBetterWealth')

export default {
  name: "WhatIsBetterWealth",
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn
  },

  methods: {
    showModal () {
      this.$emit("showModal")
    }
  }
};
</script>

<style scoped></style>